import { callApi } from '@/api/apibase'

export const createCustomerRegistrationRelation = function(pNumber, registrationNumber, paymentType, selectedCreditCardId, selectedSubscriptionId, selectedSubscriptionOrderItemId, allowAccessControl) {
    let body = {
        PNumber: pNumber,
        RegistrationNumber: registrationNumber,
        PaymentType: paymentType,
        SelectedCreditCardId: selectedCreditCardId,
        SelectedSubscriptionId: selectedSubscriptionId,
        SelectedSubscriptionOrderItemId: selectedSubscriptionOrderItemId,
        AllowAccessControl: allowAccessControl
    }

    return callApi('POST', '/api/customerregistrationrelation/create', body)
}