import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/pages/Index'),
        children: [
            // {
            //     name: 'Login',
            //     path: '',
            //     component: () => import('@/views/registration/conditions/NorforsConditions'),
            //     meta: {
            //         title: 'Log ind'
            //     }
            // },
            {
                name: 'Login',
                path: '',
                component: () => import('@/views/pages/Login'),
                meta: {
                    title: 'Log ind'
                }
            },
            {
                name: 'CriiptoRedirectCallback',
                path: '/criipto/callback',
                component: () => import('@/views/pages/CriiptoRedirectCallback'),
                meta: {
                    title: 'Criipto callback'
                }
            },

            {
                name: 'CreateUser',
                path: '/createuser',
                component: () => import('@/views/pages/CreateUser'),
                meta: {
                    title: 'Opret bruger'
                }
            },
            {
                name: 'ForgotPassword',
                path: '/forgotpassword',
                component: () => import('@/views/pages/ForgotPassword'),
                meta: {
                    title: 'Glemt password'
                }
            },
            {
                name: 'ConfirmNewPassword',
                path: '/resetpassword',
                component: () => import('@/views/pages/CreateNewPassword'),
                meta: {
                    title: 'Glemt password'
                }
            },
            {
                name: 'CreateUserAccepted',
                path: '/createuseraccepted',
                component: () => import('@/views/pages/CreateUserAccepted'),
                meta: {
                    title: 'Bruger oprettet'
                }
            },
            {
                name: 'CardAccepted',
                path: '/cardaccepted',
                component: () => import('@/views/pages/CardAddedAccepted'),
                meta: {
                    title: 'Tilmelding af kort accepteret'
                }
            },
            {
                name: 'CardDeclined',
                path: '/carddeclined',
                component: () => import('@/views/pages/CardAddedDeclined'),
                meta: {
                    title: 'Tilmelding af kort fejlet'
                }
            },
            {
                name: 'PaymentAccepted',
                path: '/paymentaccepted',
                component: () => import('@/views/pages/PaymentAccepted'),
                meta: {
                    title: 'Betaling accepteret'
                }
            },
            {
                name: 'PaymentDeclined',
                path: '/paymentdeclined',
                component: () => import('@/views/pages/PaymentDeclined'),
                meta: {
                    title: 'Betaling fejlet'
                }
            },
            {
                name: 'EmailConfirmed',
                path: '/confirmemail',
                component: () => import('@/views/pages/EmailAccepted'),
                meta: {
                    title: 'Bekræft email'
                }
            },
            {
                name: '400',
                path: '/error',
                component: () => import('@/views/pages/Error'),
                meta: {
                    title: 'Fejl'
                }
            },
            {
                name: 'NotAllowed',
                path: '/notallowed',
                component: () => import('@/views/pages/NotAllowed'),
                meta: {
                    title: 'Ikke tilladt'
                }
            },
            {
                name: 'NoAddress',
                path: '/noaddress',
                component: () => import('@/views/pages/NoAddress'),
                meta: {
                    title: 'Ingen addresse'
                }
            },
            {
                name: 'ContactHome',
                path: '/contact',   
                component: () => import('@/views/shared/ContactInfo'),
                meta: {
                    title: 'Kontakt'
                }
            },
            {
                name: 'TermsHome',
                path: '/terms',
                component: () => import('@/views/shared/TermsInfo'),
                meta: {
                    title: 'Handelsbetingelser'
                }
            },
            {
                name: 'CookiesHome',
                path: '/cookies',
                component: () => import('@/views/shared/CookieInfo'),
                meta: {
                    title: 'Cookie- og privatlivspolitik'
                }
            },
            
        ]
    },
    {
        path: '/landingpage',
        component: () => import('@/views/landingpage/Index'),
        children: [
            {
                name: 'PNumberSelector',
                path: '/landingpage/pnumberselector',
                component: () => import('@/views/landingpage/PNumberSelector'),
                meta: {
                    title: 'Vælg P-nummer'
                }
            },
        ],
        meta: { authenticationIsRequired: true }
    },
    {
        path: '/dashboard',
        component: () => import('@/views/dashboard/Index'),
        children: [
            {
                name: 'Welcome',
                path: '/dashboard/welcome',
                component: () => import('@/views/dashboard/Welcome'),
                meta: {
                    title: 'Velkommen'
                }
            },
            {
                name: 'MyProfile',
                path: '/dashboard/myprofile',
                component: () => import('@/views/dashboard/MyProfile'),
                meta: {
                    title: 'Profil'
                }
            },
            {
                name: 'CreditCardList',
                path: '/dashboard/creditcardlist',
                component: () => import('@/views/dashboard/CreditCardList'),
                meta: {
                    title: 'Kreditkort'
                }
            },
            {
                name: 'SubscriptionList',
                path: '/dashboard/subscriptionlist',
                component: () => import('@/views/dashboard/SubscriptionList'),
                meta: {
                    title: 'Abonnementer'
                }
            },
            {
                name: 'RegistrationNumbers',
                path: '/dashboard/registrationnumbers',
                component: () => import('@/views/dashboard/RegistrationNumberList'),
                meta: {
                    title: 'Køretøjer'
                }
            },
            
            {
                name: 'EntranceList',
                path: '/dashboard/entrancelist',
                component: () => import('@/views/dashboard/EntranceList'),
                meta: {
                    title: 'Ordrer'
                }
            },
            {
                name: 'OrderList',
                path: '/dashboard/orderlist',
                component: () => import('@/views/dashboard/OrderList'),
                meta: {
                    title: 'Ordrer'
                }
            },
            {
                name: 'PurchaseSubscription',
                path: '/dashboard/purchasesubscription',
                component: () => import('@/views/components/PurchaseSubscription'),
                meta: {
                    title: 'Køb abonnement'
                }
            },
            {
                name: 'Contact',
                path: '/dashboard/contact',
                component: () => import('@/views/shared/ContactInfo'),
                meta: {
                    title: 'Kontakt'
                }
            },
            {
                name: 'Terms',
                path: '/dashboard/terms',
                component: () => import('@/views/shared/TermsInfo'),
                meta: {
                    title: 'Handelsbetingelser'
                }
            },
            {
                name: 'Cookies',
                path: '/dashboard/cookies',
                component: () => import('@/views/shared/CookieInfo'),
                meta: {
                    title: 'Cookie- og privatlivspolitik'
                }
            },
            {
                name: 'LogOut',
                path: '/dashboard/logout',
                component: () => import('@/views/dashboard/LogOut'),
                meta: {
                    title: 'Log ud'
                }
            }
        ],
        meta: { authenticationIsRequired: true }
    },
    {
        path: '/registration',
        component: () => import('@/views/registration/Index'),
        children: [
            {
                name: 'Registration',
                path: '/registration/registration',
                component: () => import('@/views/registration/Registration'),
                meta: {
                    title: 'Registrering'
                }
            },
            {
                name: 'CriiptoRedirectCallbackPrivate',
                path: '/registration/CriiptoRedirectCallbackPrivate',
                component: () => import('@/views/registration/CriiptoRedirectCallbackPrivate'),
                meta: {
                    title: 'Criipto callback'
                }
            },
        ]

    }

]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router



router.beforeEach((to, from, next) => {
    //Check if the page we are navigation to requires the user to be authorized
    const pageRequiredAuthentication = to.matched.some(route => route.meta.authenticationIsRequired)

    //If the page required authorization and the token is null. Redirect to login
    if(pageRequiredAuthentication && localStorage.getItem('token') === null){
        next({ name: 'Login' })
    }
    else{
        next()
    }
})
