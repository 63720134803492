<template>
    <v-row
        class="acceptConditions"
    >
        <v-col cols="12">
            Læs om betingelserne for brug af ubemandet åbningstid via følgende link: <br>
            <a href="https://vestfor.dk/privat/genbrugsstationer/udvidet-aabningstid-for-private" target="_blank">https://vestfor.dk/privat/genbrugsstationer/udvidet-aabningstid-for-private</a>
        </v-col>
        <v-col cols="12">
            
                <v-checkbox
                    v-model="checkboxAccept"
                    label="Jeg accepterer betingelserne."
                />
            
        </v-col>
        <v-col cols="12">
            <v-btn
                :disabled="!checkboxAccept"
                color="#75ad40"
                @click="$emit('acceptConditions')"
            >
                Accepter
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
            checkboxAccept: false
        }
    }
}

</script>