<template>
    <v-dialog
        class="opti-dialog ma-0 pa-0"
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
                color="primary"
                width="153px"
                >
                <v-icon left>mdi-car-side</v-icon>
                {{ $t('registrations-addbutton') }}
            </v-btn>
        </template>
        <v-card>
            <v-form
                ref="form"
                v-model="valid"
            >
                <v-card-title class="py-1">
                    {{ $t('customerportal-addRegistrationNumber') }}
                    <v-spacer />
                    <v-icon
                        aria-label="Close"
                        size="25"
                        @click="dialog = false"
                    >
                        mdi-close
                    </v-icon>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="registrationNumber"
                        :label="$t('customerportal-addRegistrationNumber-regnr')"
                        :disabled="working"
                        :rules="globalRegistrationNumberValidator(registrationNumber)"
                    />
                
                    <v-select
                        v-if="paymentTypeItems.length > 0 && !activeCompanySubscription"
                        v-model="selectedPaymentType"
                        :items="paymentTypeItems"
                        :label="$t('customerportal-choosePaymentMethod')"
                        item-text="text"
                        item-value="value"
                        :disabled="working"
                        :rules="globalCheckNotEmpty(selectedPaymentType)"
                    />
                    <v-select
                        v-if="!activeCompanySubscription && selectedPaymentType === 1"
                        v-model="selectedCreditCardId"
                        :items="creditCardItems"
                        :label="$t('customerportal-chooseCreditCard')"
                        item-text="maskedPan"
                        item-value="id"
                        :no-data-text="$t('customerportal-noCreditCards')"
                        :disabled="working"
                        :rules="selectedPaymentType === 1 ? globalCheckNotEmpty(selectedCreditCardId) : []"
                    />
                    <v-select
                        v-if="!activeCompanySubscription && selectedPaymentType === -1"
                        v-model="selectedSubscriptionOrderItemId"
                        :items="availableSubscriptions"
                        :label="$t('customerPortal-chooseSubscription')"
                        item-value="id"
                        item-text="title"
                        :rules="selectedPaymentType === -1 ? globalCheckNotEmpty(selectedSubscriptionOrderItemId) : []"
                    >
                        <template v-slot:item="{item, on}">
                            <v-list-item v-on="on">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t('subscriptionStart') }}: {{ item.utcPeriodStart | luxon({ input: "formatutc", output: "formatlocaldate" }) }} <br>{{ $t('subscriptionEnd') }}: {{ item.utcPeriodEnd | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>               
                    </v-select>
                    <v-row v-if="setting && setting.allowSetAccessControl">
                        <v-col cols="8">
                            <v-switch
                                v-model="allowAccessControl"
                                :label="$t('customerportal-allowAccessControl')"
                                :disabled="working"
                            />
                        </v-col>
                        <v-col cols="3" class="v-input__slot mt-1">
                            <a :href="accessControlConditionsLink" target="_blank" >{{$t('showConditions')}}</a>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        :loading="working"
                        @click="dialog = false"
                    >
                        {{ $t('buttonClose') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        :loading="working"
                        :disabled="!valid || working"
                        color="primary"
                        @click="clickAddCustomerRegistrationRelation"
                    >
                        {{ $t('registrations-addbutton') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-dialog
            v-model="dialogCreateFailed"
            max-width="500px"
        >
            <v-card
                v-if="alreadyTakenFail"
            >
                <v-card-title class="py-1">
                    {{ $t('relation-already-taken') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('relation-already-taken-info') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        :loading="working"
                        @click="dialogCreateFailed = false"
                    >
                        OK
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
            <v-card v-else-if="vehicleIsTrailer">
                <v-card-title class="py-1">
                    {{ $t('customerportal-addVehicle-trailer') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('customerportal-addVehicle-vehicleIsTrailer') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        :loading="working"
                        @click="dialogCreateFailed = false"
                    >
                        OK
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
            <v-card
                v-else-if="notOwnedByCvrFail"
            >
                <v-card-title class="py-1">
                    {{ $t('customerportal-addVehicle-error') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('customerportal-addVehicle-notOwnedByCompany') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        :loading="working"
                        @click="dialog = false"
                    >
                        OK
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
            <v-card
                v-else-if="alreadyRelatedFail"
            >
                <v-card-title class="py-1">
                    {{ $t('customerportal-addVehicle-vehicleAlreadyExists') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('customerportal-addVehicle-vehicleAlreadyExists2', {regnr: registrationNumber}) }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="dialog = false"
                    >
                        {{ $t('buttonClose') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        :loading="working"
                        @click="dialogCreateFailed = false"
                    >
                        OK
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogConfirm"
            max-width="500px"
        >
            <v-card>
                <v-card-title class="py-1">
                    {{ $t('addonpredictionvehicletype-2') }}
                </v-card-title>
                <v-card-text>
                    <h2>{{ $t('registrations-model') }}: {{ vehicleBrand }} - {{ vehicleModel }}</h2>
                    <h3 class="mb-4">
                        {{ $t('attentiontype-0') }}: {{ registrationNumber }}
                    </h3>
                    <div
                        v-if="!vehicleNotFound"
                        class="mb-4"
                    >
                        {{ $t('customerportal-addVehicle-info') }}
                    </div>
                    <span>
                        {{ $t('customerportal-addVehicle-info2') }}
                        <br><br>
                        {{ $t('customerportal-addVehicle-info3') }}
                        
                    </span>

                    <v-checkbox
                        v-model="confirmVehicleCheckbox"
                        :label="$t('customerportal-addVehicle-confirmBox')"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :loading="working"
                        @click="dialog = false"
                    >
                        {{ $t('buttonClose') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        :disabled="!confirmVehicleCheckbox"
                        :loading="working"
                        @click="clickAddConfirmCustomerRegistrationRelation"
                    >
                        {{ $t('customerportal-addVehicle-yes') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default{
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        availableSubscriptions: {
            type: Array,
            default: () => []
        },
        creditCardItems: {
            type: Array,
            default: () => []
        },
        selectedCreditCardId: {
            type: Number,
            default: null
        },
    },
    data(){
        return{
            dialog: false,
            registrationNumber: '',
            selectedPaymentType: 0,
            selectedSubscriptionOrderItemId: null,
            allowAccessControl: false,
            valid: false,
            working: false,
            setting: null,
            accessControlConditionsLink: null,
            activeCompanySubscription: null,
            paymentTypeItems: [],

            selectedPNumber: this.getSelectedPNumber,

            //CONFIRM DIALOG
            dialogConfirm: false,
            vehicleBrand: null,
            vehicleModel: null,
            vehicleNotFound: false,
            confirmVehicleCheckbox: false,

            //CREATE FAILED DIALOG
            dialogCreateFailed: false,
            alreadyTakenFail: false,
            notOwnedByCvrFail: false,
            alreadyRelatedFail: false,
            vehicleIsTrailer: false,

        }
    },
    computed: {
        vehicleIdentificator(){
            return this.vehicleIdentification ? this.vehicleIdentification : this.$t('vehicledriveforcetype-0')
        },
        allowPbP(){
            return process.env.VUE_APP_ALLOW_PAY_BY_PLATE === 'true'
        },
        ...mapGetters({
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber',
            GetSubscriptionMode: 'GetSubscriptionMode',
            GetSubscriptionEnabled: 'GetSubscriptionEnabled'
        })
    },
    created() {
        this.working = true
        this.GetSystemSetting({type: 5}).then(result => {
            this.setting = result.setting
            this.accessControlConditionsLink = result.setting.accessControlConditionsLink
        })
        if(this.GetSubscriptionEnabled){            
            if(this.GetSubscriptionMode === 0){
                this.paymentTypeItems.push(
                    {
                        value: 0,
                        text: this.$t('customerportal-paymentMethod-automatic')
                    }
                )
                this.paymentTypeItems.push(
                    {
                        value: -1,
                        text: this.$t('modulename-Subscription')
                    }
                )
            }
        }

    
        this.working = false
    },
    methods: {
        clickAddCustomerRegistrationRelation(){
            this.working = true
            this.FetchCustomerRegistrationDetails({
                pNumber: this.getSelectedPNumber,
                registrationNumber: this.registrationNumber
            })
                .then(result => {
                    this.working = false
                    if(!result.success){

                        if(result.codes.includes('registrationnumberhasexistingrelation')){
                            this.alreadyTakenFail = true
                        }  
                        else if(result.codes.includes('registrationnumberalreadyrelated')){
                            this.alreadyRelatedFail = true         
                        } 
                        else if(result.codes.includes('registrationnumbernotownedbythecvrnumber')){
                            this.notOwnedByCvrFail = true
                        }else if(result.codes.includes('customerregistrationrelationunilockupdatedfailed')){
                            this.unilockerror = true
                        } else if(result.codes.includes('registrationnumberisatrailer')){
                            this.vehicleIsTrailer = true
                        }
                        this.dialogCreateFailed = true
                    }else{
                        this.vehicleNotFound = false
                        if(result.codes.includes('vehicleinfofoundfailed')){
                            this.vehicleNotFound = true
                        }
                        this.vehicleBrand = result.vehicleBrand
                        this.vehicleModel = result.vehicleModel
                        this.dialogConfirm = true
                    }                    
                })            
        },
        clickAddConfirmCustomerRegistrationRelation(){
            this.working = true

            this.FetchCreateCustomerRegistrationRelation({
                pNumber: this.getSelectedPNumber,
                registrationNumber: this.registrationNumber,
                //When selecting "Abonnement" the payment type should be "Faktura ( = 0)"
                paymentType: this.selectedPaymentType === -1 ? 0 : this.selectedPaymentType,
                selectedCreditCardId: this.selectedPaymentType === 1 ? this.selectedCreditCardId : null,
                selectedSubscriptionOrderItemId:  this.selectedPaymentType === -1 ? this.selectedSubscriptionOrderItemId : null,
                allowAccessControl: this.allowAccessControl
            })
                .then(result => {              
                    if(!result.success){

                        if(result.codes.includes('registrationnumberhasexistingrelation')){
                            this.alreadyTakenFail = true
                        }   
                        else if(result.codes.includes('registrationnumberalreadyrelated')){
                            this.alreadyRelatedFail = true         
                        }
                        else if(result.codes.includes('registrationnumbernotownedbythecvrnumber')){
                            this.notOwnedByCvrFail = true
                        }                       
                        this.dialogCreateFailed = true
                    }
                    //This means the user selected "Abonnement" so we need to open the
                    //webshop dialog
                    else if(this.GetSubscriptionEnabled && this.selectedPaymentType === -1 && result.newRelationId){
                        this.relationIdToAddToSubscription = result.newRelationId
                        this.purchaseSubscriptionDialog = true
                    }
                    
                }).then(() => {        
                    this.$emit('relation-added')            
                    this.dialog = false

                })
        },
        ...mapActions({
            FetchCustomerRegistrationRelationList: 'FetchCustomerRegistrationRelationList',
            FetchUser: 'FetchUser',
            FetchCustomerRegistrationDetails: 'FetchCustomerRegistrationDetails',
            FetchCreateCustomerRegistrationRelation: 'FetchCreateCustomerRegistrationRelation',
            FetchUpdateCustomerRegistrationRelation: 'FetchUpdateCustomerRegistrationRelation',
            FetchDeleteCustomerRegistrationRelation: 'FetchDeleteCustomerRegistrationRelation',
            FetchUserCreditCardList: 'FetchUserCreditCardList',
            FetchSubscriptionOrderItemsNotActivated: 'FetchSubscriptionOrderItemsNotActivated',
            GetSystemSetting: 'GetSystemSetting'
        })
    }
}
</script>