<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <v-card>
                    <v-card-title class="py-1 mb-1">
                        {{ $t('customerportal-registrationsList') }}
                        <v-spacer />
                        <add-relation-dialog
                            v-if="!working"
                            :availableSubscriptions="availableSubscriptions"
                            :creditCardItems="creditCardItems"   
                            :selectedCreditCardId="selectedCreditCardId"
                            @relation-added="getList"
                        >
                    </add-relation-dialog>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="customerRegistrationRelationList"
                        :items-per-page="50"
                        class="elevation-2"
                        :footer-props="{
                            itemsPerPageOptions: [10,20,50, -1],
                            'items-per-page-text': $t('entranceList-nrOfItems')
                        }"
                        @click:row="clickUpdateCustomerRegistrationRelation"
                    >
                        <template v-slot:item.utcPeriodStart="{ item }">
                            {{ item.utcPeriodStart | luxon({ input: "formatutc", output: "formatlocaldate" }) }}
                        </template>

                        <template v-slot:item.paymentType="{ item }">
                            <div v-if="GetSubscriptionEnabled && GetSubscriptionMode === 0 && item.activeSubscription">
                                {{ $t('status-4-0-8') }}                                                             
                            </div>
                            <div v-else>
                                {{ $t("customer-registration-relation-paymenttype-" + item.paymentType) }}
                                <br>
                                <i
                                    v-if="GetSubscriptionEnabled && GetSubscriptionMode === 1 && activeCompanySubscription"
                                    style="font-size: 15px; font-weight: bold; color:green;"
                                >
                                    {{ $t('customerportal-activeCompanySubscription') }}
                                </i> 
                                <i
                                    v-else-if="GetSubscriptionEnabled && GetSubscriptionMode === 1 && upcomingCompanySubscription"
                                    style="font-size: 15px; font-weight: bold; color:green;"
                                >
                                    {{ $t('customerportal-pendingSubscription') }}
                                </i> 
                                <i
                                    v-else-if="GetSubscriptionEnabled && GetSubscriptionMode === 0 && !item.activeSubscription && item.subscriptionList && item.subscriptionList.length > 0"
                                    style="font-size: 15px; font-weight: bold; color:green;"
                                >
                                    {{ $t('customerportal-pendingSubscription') }}
                                </i> 
                            </div>
                        </template>

                        <template v-slot:item.subscription="{ item }">
                            <div v-if="activeCompanySubscription">
                                <b style="font-size:10px;">{{ activeCompanySubscription.title }}</b>
                                <br>
                                <b style="font-size:10px;">{{ $t('subscriptionEnd') }}: {{ activeCompanySubscription.utcSubscriptionEnd | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</b>
                                <br>
                                <b
                                    v-if="activeCompanySubscription.entrancesLeft"
                                    style="font-size:10px;"
                                >
                                    {{ $t('customerportal-entrancesLeft') }}: {{ activeCompanySubscription.entrancesLeft }}
                                </b>
                            </div>
                            <div v-else-if="item.activeSubscription">
                                <b style="font-size:10px;">{{ item.activeSubscription.title }}</b>
                                <br>
                                <b style="font-size:10px;">{{ $t('subscriptionEnd') }}: {{ item.activeSubscription.subscriptionRelations.find(x => x.customerRegistrationRelationId === item.id).utcPeriodEnd | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</b>
                                <br>
                                <b
                                    v-if="item.activeSubscription.entrancesLeft"
                                    style="font-size:10px;"
                                >
                                    {{ $t('customerportal-entrancesLeft') }}: {{ item.activeSubscription.entrancesLeft }}
                                </b>
                            </div>
                            <!-- item.subscriptionList[0] != null is  bad solution, but I can't figure out why it happens  -->
                            <div v-else-if="item.subscriptionList && item.subscriptionList.length > 0 && item.subscriptionList.find(x => x.subscriptionRelations.filter(y => y.customerRegistrationRelationId === item.id)) != null">
                                <b style="font-size:10px;">{{ item.subscriptionList.find(x => x.subscriptionRelations.filter(y => y.customerRegistrationRelationId === item.id)).title }}</b>
                                <br>
                                <b style="font-size:10px;">{{ $t('subscriptionStart') }}: {{ item.subscriptionList.find(x => x.subscriptionRelations.filter(y => y.customerRegistrationRelationId === item.id)).utcPeriodStart | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</b>
                                <br>
                                <b style="font-size:10px;">{{ $t('subscriptionEnd') }}: {{ item.subscriptionList.find(x => x.subscriptionRelations.filter(y => y.customerRegistrationRelationId === item.id)).utcPeriodEnd | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</b>
                                <br>
                                <b
                                    v-if="item.subscriptionList.find(x => x.subscriptionRelations.filter(y => y.customerRegistrationRelationId === item.id)).entrancesLeft"
                                    style="font-size:10px;"
                                >
                                    {{ $t('customerportal-entrancesLeft') }}: {{ item.subscriptionList.find(x => x.subscriptionRelations.filter(y => y.customerRegistrationRelationId === item.id)).entrancesLeft }}
                                </b>
                            </div>
                        </template>

                        <template v-slot:item.allowAccessControl="{ item }">
                            <v-icon
                                v-if="item.allowAccessControl"
                                color="green"
                            >
                                mdi-check
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        
            <v-dialog
                v-model="dialogUpdate"
                max-width="500px"
                @click:outside="getList(); dialogUpdate = false; addSubscriptionToRelation = false; selectedSubscriptionOrderItemId = null;"
            >
                <v-card>
                    <v-card-title class="py-1">
                        {{ $t('customerportal-updateVehicle') }}
                        <v-spacer />

                        <v-icon
                            aria-label="Close"
                            size="25"
                            @click="getList(); dialogUpdate = false; addSubscriptionToRelation = false; selectedSubscriptionOrderItemId = null;"
                        >
                            mdi-close
                        </v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="relationToUpdate.registrationNumber"
                            :label="$t('imagetype-3')"
                            readonly
                            :disabled="working"
                        />
                        <v-select
                            v-if="paymentTypeItemsForUpdateDialog.length > 0 && !relationToUpdate.activeSubscription"
                            v-model="relationToUpdate.paymentType"
                            :items="paymentTypeItemsForUpdateDialog"
                            :label="$t('customerportal-choosePaymentMethod')"
                            item-text="text"
                            item-value="value"
                            :disabled="working "
                        />

                        <v-select
                            v-if="addSubscriptionToRelation || (!activeCompanySubscription && relationToUpdate.paymentType === -1)"
                            v-model="selectedSubscriptionOrderItemId"
                            :items="availableSubscriptions"
                            :label="$t('customerPortal-chooseSubscription')"
                            item-value="id"
                            item-text="title"
                            :rules="relationToUpdate.paymentType === -1 ? globalCheckNotEmpty(selectedSubscriptionOrderItemId) : []"
                        >
                            <template v-slot:item="{item, on}">
                                <v-list-item v-on="on">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $t('subscriptionStart') }}: {{ item.utcPeriodStart | luxon({ input: "formatutc", output: "formatlocaldate" }) }} <br>{{ $t('subscriptionEnd') }}: {{ item.utcPeriodEnd | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>               
                        </v-select>
                        <v-simple-table v-if="GetSubscriptionEnabled && GetSubscriptionMode === 0 && relationToUpdate.subscriptionList && relationToUpdate.subscriptionList.length > 0">
                            <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <b>{{ $t('subscriptionlist') }}</b>
                                        </td>
                                        <td width="20%">
                                            <b>{{ $t('subscription-start') }}</b>
                                        </td>
                                        <td width="20%">
                                            <b>{{ $t('subscription-end') }}</b>
                                        </td>
                                        <td
                                            width="10%"
                                            class="text-right"
                                        >
                                            <b>{{ $t('customerportal-entrancesLeft') }}</b>
                                        </td>
                                    </tr>
                                    <template v-for="subscription in relationToUpdate.subscriptionList">
                                        <tr :key="subscription.id">
                                            <td>{{ subscription.title }}</td>
                                            <td>{{ subscription.utcPeriodStart | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</td>
                                            <td>{{ subscription.utcPeriodEnd | luxon({ input: "formatutc", output: "formatlocaldate" }) }}</td>
                                            <td
                                                v-if="subscription.entrancesLeft"
                                                class="text-right"
                                            >
                                                {{ subscription.entrancesLeft }}
                                            </td>
                                            <td
                                                v-else
                                                class="text-right"
                                            >
                                                {{ $t('subscription-unlimited') }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-row v-if="setting && setting.allowSetAccessControl">
                            <v-col cols="6" v-if="GetSubscriptionMode === 0 && !addSubscriptionToRelation && relationToUpdate.activeSubscription">
                            </v-col>
                            <v-col cols="6" v-if="GetSubscriptionMode === 0 && !addSubscriptionToRelation && relationToUpdate.activeSubscription">
                                <v-spacer />
                                <v-btn
                                    :loading="working"
                                    @click="addSubscriptionToRelation = true; relationToUpdate.paymentType = -1"
                                >
                                    Tilknyt abonnement
                                </v-btn>
                            </v-col>
                            <v-col cols="8">
                                <v-switch
                                    v-model="relationToUpdate.allowAccessControl"
                                    :label="$t('customerportal-allowAccessControl')"
                                    :disabled="working"
                                />
                            </v-col>
                            <v-col cols="3" class="v-input__slot mt-1">
                                <a :href="accessControlConditionsLink" target="_blank" >{{$t('showConditions')}}</a>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            @click="dialogUpdate = false"
                        >
                            {{ $t('registrations-nytView-abortButton') }}
                        </v-btn>
                        <v-btn
                            :loading="working"
                            @click="clickDeleteCustomerRegistrationRelation"
                        >
                            {{ $t('registrations-updateViewButton-deleteButton-deleteButton') }}
                        </v-btn>
                        <v-spacer />

                        <v-btn
                            :loading="working"
                            :disabled="(relationToUpdate.paymentType === -1 && !selectedSubscriptionOrderItemId) || (relationToUpdate.paymentType === 1 && !relationToUpdate.selectedCreditCardId)"
                            @click="clickUpdateConfirmCustomerRegistrationRelation"
                        >
                            {{ $t('attentions/attentioncontactlist-attentionsContacts-refreshButtonHoover') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="dialogDelete"
                max-width="500px"
            >
                <v-card>
                    <v-card-title class="py-1">
                        {{ $t('customerportal-deleteVehicle') }}
                        <v-spacer />
                        <v-icon
                            aria-label="Close"
                            size="25"
                            
                            @click="dialogDelete = false"
                        >
                            mdi-close
                        </v-icon>
                    </v-card-title>
                    <v-card-text>
                        {{ $t('registrations-updateViewButton-deleteButton-wishToDelete') }}
                        <br>
                        <v-text-field
                            v-model="relationToUpdate.registrationNumber"
                            label="Nummerplade"
                            readonly
                            :disabled="working"
                        />

                        <div v-if="GetSubscriptionEnabled && relationToUpdate && relationToUpdate.subscriptionList && relationToUpdate.subscriptionList.length > 0">
                            <b>{{ $t('addonSettings-boldNotice') }}</b> 
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            @click="dialogDelete = false"
                        >
                            {{ $t('registrations-nytView-abortButton') }}
                        </v-btn>
                        <v-btn
                            :loading="working"
                            @click="clickConfirmDeleteCustomerRegistrationRelation"
                        >
                            {{ $t('registrations-updateViewButton-deleteButton') }}
                        </v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="informationSubscriptionDialog"
                max-width="500px"
            >
                <v-card>
                    <v-card-title class="py-1">
                        {{ $t('customerportal-editVehicle-cantDelete') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('customerportal-editVehicle-cantDelete-existingsubscription') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            :loading="working"
                            @click="informationSubscriptionDialog = false"
                        >
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddRelationDialog from '../components/AddRelationDialog.vue'

export default {
    components: {
        AddRelationDialog
    },
    data() {
        return {
            working: false,

            setting: null,

            customerRegistrationRelationList: [],
            headers: [
                {
                    text: this.$t('attentiontype-0'),
                    align: 'start',
                    sortable: true,
                    value: 'registrationNumber',
                },
                {
                    text: this.$t('addonpredictionvehicletype-2'),
                    align: 'start',
                    sortable: true,
                    value: 'vehicleBrand',
                },
                {
                    text: this.$t('registrations-model'),
                    align: 'start',
                    sortable: true,
                    value: 'vehicleModel',
                },
                {
                    text: this.$t('agGrid-validFrom'),
                    align: 'start',
                    sortable: true,
                    value: 'utcPeriodStart',
                },
                {
                    text: this.$t('customer-relation-paymentMethod'),
                    align: 'start',
                    sortable: true,
                    value: 'paymentType',
                }
            ],
            availableSubscriptions: [],
            //FORM
            valid: false,
            registrationNumber: null,
            paymentTypeItems: [],
            paymentTypeItemsForUpdateDialog: [],
            creditCardItems: [],
            selectedPaymentType: null,            
            selectedCreditCardId: null,

            //This is the selected p-number in the list when you create a NEW relation
            selectedPNumber: null,


            //DELETE DIALOG
            dialogDelete: false,

            //UPDATE DIALOG
            dialogUpdate: false,
            relationToUpdate: false,
            addSubscriptionToRelation: false,

            //CONFIRM DIALOG
            dialogConfirm: false,
            vehicleBrand: null,
            vehicleModel: null,
            vehicleNotFound: false,
            confirmVehicleCheckbox: false,

            //CREDIT CARD DIALOG
            creditCardDialog: false,

            //SUBSCRIPTION BUY DIALOG
            purchaseSubscriptionDialog: false,
            relationIdToAddToSubscription: null,
            informationSubscriptionDialog: false,
            successDialog: false,
            selectedSubscriptionOrderItemId: null,
            activeCompanySubscription: null,

            //UNILOCK 
            unilockerror: false,

            allowAccessControl: false,

            upcomingCompanySubscription: null,
        }
    },          
    computed: {
        vehicleIdentificator(){
            return this.vehicleIdentification ? this.vehicleIdentification : this.$t('vehicledriveforcetype-0')
        },
        allowPbP(){
            return process.env.VUE_APP_ALLOW_PAY_BY_PLATE === 'true'
        },
        ...mapGetters({
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber',
            GetSubscriptionMode: 'GetSubscriptionMode',
            GetSubscriptionEnabled: 'GetSubscriptionEnabled'
        })
    },
    watch: {
        registrationNumber: function(value){
            if (value) {
                this.registrationNumber = value.replace(' ', '').toUpperCase()
            }            
        },
        purchaseSubscriptionDialog: function(value){
            if(!value){
                this.relationIdToAddToSubscription = null
            }
        }
    },
    created() {
        this.working = true

        this.GetSystemSetting({type: 5}).then(result => {
            this.setting = result.setting
            this.accessControlConditionsLink = result.setting.accessControlConditionsLink

            if(this.setting.allowSetAccessControl){
                this.headers.push({
                    text: this.$t('allowedAccessControl'),
                    align: 'start',
                    sortable: true,
                    value: 'allowAccessControl',
                })
            }
        })


        if(this.GetSubscriptionEnabled){
            this.headers.push({
                text: this.$t('modulename-Subscription'),
                align: 'start',
                sortable: true,
                value: 'subscription',
            })
            
            if(this.GetSubscriptionMode === 0){
                this.paymentTypeItems.push(
                    {
                        value: 0,
                        text: this.$t('customerportal-paymentMethod-automatic')
                    }
                )
                this.paymentTypeItems.push(
                    {
                        value: -1,
                        text: this.$t('modulename-Subscription')
                    }
                )
                this.paymentTypeItemsForUpdateDialog.push(
                    {
                        value: 0,
                        text: this.$t('customerportal-paymentMethod-automatic')
                    }
                )
                this.paymentTypeItemsForUpdateDialog.push(
                    {
                        value: -1,
                        text: this.$t('modulename-Subscription')
                    }
                )

                this.selectedPaymentType = 0
                this.FetchSubscriptionOrderItemsNotActivated({pNumber: this.getSelectedPNumber}).then(result => {
                    this.availableSubscriptions = result.subscriptions
                })
            }
            

            if(this.GetSubscriptionMode === 1){
                this.FetchUser({pNumber: this.getSelectedPNumber}).then(result => {
                    this.activeCompanySubscription = result.customer.activeCompanySubscription

                    this.upcomingCompanySubscription = result.customer.upcomingCompanySubscription


                    //If there is an active company subscription, we need to set the payment type to some default value - For now that's Invoice
                    if(this.activeCompanySubscription){
                        this.selectedPaymentType = 0
                    }
                })
            }
        }
     
        this.getUserInformation()
        this.working = false
    },
    methods: {
        getUserInformation(){
            //NOTE: This is the only time we set the selected pnumber to the global one in this component
            //The rest of the time we use the selected p number from the local p number selector because this is the one who
            //determines where the relation should be attached
            this.selectedPNumber = this.getSelectedPNumber


            this.getList()

        },
        clickAddCustomerRegistrationRelation(){
            this.working = true
            this.FetchCustomerRegistrationDetails({
                pNumber: this.getSelectedPNumber,
                registrationNumber: this.registrationNumber
            })
                .then(result => {
                    this.working = false
                    if(!result.success){

                        if(result.codes.includes('registrationnumberhasexistingrelation')){
                            this.alreadyTakenFail = true
                        }  
                        else if(result.codes.includes('registrationnumberalreadyrelated')){
                            this.alreadyRelatedFail = true         
                        } 
                        else if(result.codes.includes('registrationnumbernotownedbythecvrnumber')){
                            this.notOwnedByCvrFail = true
                        }else if(result.codes.includes('customerregistrationrelationunilockupdatedfailed')){
                            this.unilockerror = true
                        } else if(result.codes.includes('registrationnumberisatrailer')){
                            this.vehicleIsTrailer = true
                        }
                        this.dialogCreateFailed = true
                    }else{
                        this.vehicleNotFound = false
                        if(result.codes.includes('vehicleinfofoundfailed')){
                            this.vehicleNotFound = true
                        }
                        this.vehicleBrand = result.vehicleBrand
                        this.vehicleModel = result.vehicleModel
                        this.dialogConfirm = true
                    }                    
                })            
        },
        clickAddConfirmCustomerRegistrationRelation(){
            this.working = true

            this.FetchCreateCustomerRegistrationRelation({
                pNumber: this.selectedPNumber,
                registrationNumber: this.registrationNumber,
                //When selecting "Abonnement" the payment type should be "Faktura ( = 0)"
                paymentType: this.selectedPaymentType === -1 ? 0 : this.selectedPaymentType,
                selectedCreditCardId: this.selectedPaymentType === 1 ? this.selectedCreditCardId : null,
                selectedSubscriptionOrderItemId: this.selectedSubscriptionOrderItemId,
                allowAccessControl: this.allowAccessControl
            })
                .then(result => {              
                    if(!result.success){

                        if(result.codes.includes('registrationnumberhasexistingrelation')){
                            this.alreadyTakenFail = true
                        }   
                        else if(result.codes.includes('registrationnumberalreadyrelated')){
                            this.alreadyRelatedFail = true         
                        }
                        else if(result.codes.includes('registrationnumbernotownedbythecvrnumber')){
                            this.notOwnedByCvrFail = true
                        }                       
                        this.dialogCreateFailed = true
                    }
                    //This means the user selected "Abonnement" so we need to open the
                    //webshop dialog
                    else if(this.GetSubscriptionEnabled && this.selectedPaymentType === -1 && result.newRelationId){
                        this.relationIdToAddToSubscription = result.newRelationId
                        this.purchaseSubscriptionDialog = true
                    }
                    
                }).then(() => {                    
                    this.getList()
                    this.clickCloseConfirmDialog()

                })
        },
        clickUpdateCustomerRegistrationRelation(item){
            this.relationToUpdate = item
            this.dialogUpdate = true
        },
        clickUpdateConfirmCustomerRegistrationRelation(){
            this.working = true

            this.FetchUpdateCustomerRegistrationRelation({
                pNumber: this.selectedPNumber,
                customerRegistrationRelationId: this.relationToUpdate.id,
                paymentType: this.relationToUpdate.paymentType === -1 ? 0 : this.relationToUpdate.paymentType,
                selectedCreditCardId: this.relationToUpdate.paymentType === 1 ? this.relationToUpdate.selectedCreditCardId : null,
                selectedSubscriptionId: this.relationToUpdate.paymentType === -1 ? this.selectedSubscriptionOrderItemId : null,
                allowAccessControl: this.relationToUpdate.allowAccessControl
            })
                .then(() => {
                    this.getList()
                    this.dialogUpdate = false
                })
        },
        clickDeleteCustomerRegistrationRelation(){            
            if(this.GetSubscriptionEnabled && this.relationToUpdate && this.relationToUpdate.activeSubscription){
                this.informationSubscriptionDialog = true
            }else if(this.GetSubscriptionEnabled && this.relationToUpdate && this.relationToUpdate.subscriptionList && this.relationToUpdate.subscriptionList.length > 0){
                this.informationSubscriptionDialog = true
            }
            else{
                this.dialogDelete = true

            }
        },
        clickConfirmDeleteCustomerRegistrationRelation(){
            this.working = true

            this.FetchDeleteCustomerRegistrationRelation({
                pNumber: this.selectedPNumber,
                customerRegistrationRelationId: this.relationToUpdate.id
            })
                .then(() => {
                    this.getList()
                    this.dialogDelete = false
                    this.dialogUpdate = false
                })
        },
        clickCloseCreateFailedDialog(){
            this.dialogCreateFailed = false
            this.resetAddRegistrationForm()
        },
        clickCloseConfirmDialog(){
            this.dialogConfirm = false
            this.resetAddRegistrationForm()
        },
        resetAddRegistrationForm(){
            this.registrationNumber = null
            
            if(this.creditCardItems.length > 0){
                this.selectedPaymentType = 1
                this.selectedCreditCardId = this.creditCardItems[0].id
            }else{
                this.selectedPaymentType = 0
            }

            this.FetchSubscriptionOrderItemsNotActivated({pNumber: this.getSelectedPNumber}).then(result => {
                this.availableSubscriptions = result.subscriptions
            })

            //Also reset fields for the confirm dialog
            this.vehicleBrand = null
            this.vehicleModel = null
            this.confirmVehicleCheckbox = false

            //And also reset the create failed dialog fields
            this.alreadyTakenFail = false
            this.notOwnedByCvrFail = false
            this.alreadyRelatedFail = false
            this.vehicleIsTrailer = false

            this.$refs.form.resetValidation()
        },
        getList(){
            this.working = true
            if(this.getSelectedPNumber != null){   
                this.FetchCustomerRegistrationRelationList({pNumber: this.getSelectedPNumber})
                    .then(result => {
                        this.customerRegistrationRelationList = result.customerRegistrationRelationList

                        this.working = false
                    })
                this.FetchSubscriptionOrderItemsNotActivated({pNumber: this.getSelectedPNumber}).then(result => {
                    this.availableSubscriptions = result.subscriptions
                })
            }
        },
        closePurchaseSubscriptionDialogWithSuccess(){
            this.purchaseSubscriptionDialog = false
            this.getList()
            this.successDialog = true
        },
        closePurchaseSubscriptionDialog(){
            this.purchaseSubscriptionDialog = false
            this.getList()
        },
        ...mapActions({
            FetchCustomerRegistrationRelationList: 'FetchCustomerRegistrationRelationList',
            FetchUser: 'FetchUser',
            FetchCustomerRegistrationDetails: 'FetchCustomerRegistrationDetails',
            FetchCreateCustomerRegistrationRelation: 'FetchCreateCustomerRegistrationRelation',
            FetchUpdateCustomerRegistrationRelation: 'FetchUpdateCustomerRegistrationRelation',
            FetchDeleteCustomerRegistrationRelation: 'FetchDeleteCustomerRegistrationRelation',
            FetchUserCreditCardList: 'FetchUserCreditCardList',
            FetchSubscriptionOrderItemsNotActivated: 'FetchSubscriptionOrderItemsNotActivated',
            GetSystemSetting: 'GetSystemSetting'
        })
    }
}
</script>