<template>
   <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <credit-card-list 
                    v-if="allowPbP"
                    @creditCardChanged="getUserInformation"
                />
            </v-col>
        </v-row>
   </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default{
    components: {
        CreditCardList : () => import('@/views/components/CreditCardList.vue')
    },
    computed: {
        vehicleIdentificator(){
            return this.vehicleIdentification ? this.vehicleIdentification : this.$t('vehicledriveforcetype-0')
        },
        allowPbP(){
            return process.env.VUE_APP_ALLOW_PAY_BY_PLATE === 'true'
        },
        ...mapGetters({
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'
        })
    },
    methods: {
        getUserInformation(){
            if(process.env.VUE_APP_ALLOW_PAY_BY_PLATE === 'true'){
                this.paymentTypeItems.unshift(
                    {
                        value: 1,
                        text: this.$t('customerportal-paymentMethod-creditcard')
                    }
                )
                this.paymentTypeItemsForUpdateDialog.unshift(
                    {
                        value: 1,
                        text: this.$t('customerportal-paymentMethod-creditcard')
                    }
                )
            }
            //NOTE: This is the only time we set the selected pnumber to the global one in this component
            //The rest of the time we use the selected p number from the local p number selector because this is the one who
            //determines where the relation should be attached
            this.selectedPNumber = this.getSelectedPNumber

            Promise.all([
                this.FetchUserCreditCardList({pNumber: this.getSelectedPNumber})
            ])
                .then(results => {
                    this.creditCardItems = results[0].customerCreditCardList
                    if(this.creditCardItems.length > 0){
                        this.selectedPaymentType = 1
                        this.selectedCreditCardId = this.creditCardItems[0].id
                    }else{
                        this.selectedPaymentType = 0
                    }

                    this.getList()
                })
        },
        ...mapActions({
            FetchUserCreditCardList: 'FetchUserCreditCardList',
            GetSystemSetting: 'GetSystemSetting'
        })
    }
}
</script>