<template>
    <v-container fluid>
         <v-row> 
             <v-col
                 cols="12"
                 md="8">
                 <subscription-list
                     v-if="allowSubscription"
                 >
 
           
                 </subscription-list>
             </v-col>
         </v-row>
    </v-container>
 </template>
 
 <script>
 import { mapGetters, mapActions } from 'vuex'
 
 export default{
     data() {
         return {
             allowSubscription: false,
         }
     },
     components: {
         SubscriptionList : () => import('@/views/components/SubscriptionList.vue')
     },
     mounted(){
             this.allowSubscription = this.GetSubscriptionEnabled
     },
     computed: {
         vehicleIdentificator(){
             return this.vehicleIdentification ? this.vehicleIdentification : this.$t('vehicledriveforcetype-0')
         },
         allowPbP(){
             return process.env.VUE_APP_ALLOW_PAY_BY_PLATE === 'true'
         },
         ...mapGetters({
             getSelectedCvrNumber: 'getSelectedCvrNumber',
             getSelectedPNumber: 'getSelectedPNumber',
             GetSubscriptionEnabled: 'GetSubscriptionEnabled'
         })
     },
     methods: {
         ...mapActions({
             GetSystemSetting: 'GetSystemSetting'
         })
     }
 }
 </script>