<template>
    <div>
        <v-card v-if="working">

        </v-card>
        <v-card v-else>
            <v-card-title class="py-1">
                {{ $t('subscriptionList-title') }}
                <v-spacer></v-spacer>
                <v-dialog
                    v-if="allowSubscription"
                    v-model="purchaseSubscriptionDialog"
                    max-width="1000px"
                >
                <template v-slot:activator="{ on }">
                    <v-btn
                        :disabled="working"
                        color="primary"
                        v-on="on"
                    >
                        {{ $t('customerportal-purchaseSubscription') }}
                    </v-btn>
            </template>
            <purchase-subscription 
                v-if="purchaseSubscriptionDialog"
                :relation-id="relationIdToAddToSubscription"
                @close="purchaseSubscriptionDialog = false"
                @closesuccess="closePurchaseSubscriptionDialogWithSuccess()"
                />
            </v-dialog>
             
            </v-card-title>

            <v-card-text>
            

            </v-card-text>
       
            <v-data-table
                    :headers="headers"
                    :items="activeSubscriptions"
                    :items-per-page="20"
                    class="elevation-2"
                    :footer-props="{
                        itemsPerPageOptions: [10,20,50, -1],
                        'items-per-page-text': $t('entranceList-nrOfItems')
                    }"
                    >
                    <template v-slot:item.subscriptionRelations="{item}" >
                        <div v-if="item.subscriptionRelations">

                            <span v-for="(obj, index) in item.subscriptionRelations" :key="index">{{ obj.customerRegistrationNumberRelation ? obj.customerRegistrationNumberRelation.registrationNumber : '' }}</span>
                        </div>
                        <div v-else>
                            <span style="color: red">{{ $t('subscriptionList-noVehicles') }}</span>
                        </div>
                        
                    </template>
                 
                    <template v-slot:item.unitPriceWithoutVatInMinorCurrency="{item}">
                        {{ globalConvertMinorCurrencyToLocal(item.unitPriceWithoutVatInMinorCurrency) }}
                    </template>
            </v-data-table>
        </v-card>
        <v-dialog
                v-model="successDialog"
                max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        {{ $t('subscription-purchase-success') }}
                    </v-card-title>
                    <v-card-text>
                        <p v-if="GetSubscriptionMode == 0">
                            {{ $t('subscription-relation-info') }}
                            <br>
                            {{ $t('subscription-relation-info2') }}
                            <br>
                            <span style="color:red; font-size:18px">{{ $t('subscription-relation-info3') }}</span>

                        </p>
                        <p v-else-if="GetSubscriptionMode == 1">
                            {{ $t('subscription-company-info') }}
                        </p>
                        <br>
                        <v-col
                            class="text-center"
                        >
                            <v-icon
                                size="100"
                                color="green"
                            >
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            class="justify-center text-center"
                            :loading="working"
                            @click="successDialog = false; getList()"
                        >
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="paymentErrorDialog"
                max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        {{ $t('payment-error') }}
                    </v-card-title>
                    <v-card-text>
                            {{ $t('payment-error2') }}
                            <br>
                            {{  $t('eksportstatus-30')}}: {{ $t(`freepayStatusCode-${gatewayReason}`) }} ({{ gatewayReason }})
                        <br>
                        <v-col
                            class="text-center"
                        >
                            <v-icon
                                size="100"
                                color="red"
                            >
                                mdi-close-circle
                            </v-icon>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            class="justify-center text-center"
                            :loading="working"
                            @click="paymentErrorDialog = false"
                        >
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default{
    data() {
        return {
            purchaseSubscriptionDialog: false,

            working: true,

            activeSubscriptions: [],
            inactiveSubscriptions: [],
            notActivatedSubscriptions: [],
            successDialog: false,
            paymentErrorDialog: false,
            gatewayReason: null,

            headers: [
                {
                    text: this.$t('purchaseDate'),
                    align: 'start',
                    sortable: true,
                    value: 'utcDateCreated',
                },
                {
                    text: this.$t('settings/productadministration-priceExclVat'),
                    align: 'end',
                    sortable: true,
                    value: 'unitPriceWithoutVatInMinorCurrency',
                },
                {
                    text: this.$t('title'),
                    align: 'start',
                    sortable: true,
                    value: 'title',
                },
                {
                    text: this.$t('subscriptionStart'),
                    align: 'start',
                    sortable: true,
                    value: 'utcSubscriptionStart',
                },
                {
                    text: this.$t('subscriptionEnd'),
                    align: 'start',
                    sortable: true,
                    value: 'utcSubscriptionEnd',
                }
            ],
            inactiveHeaders: [
                {
                    text: this.$t('title'),
                    align: 'start',
                    sortable: true,
                    value: 'title',
                },
            ]

        }
    },
    created(){
        this.working = true
      
        if(this.$route.query.paymentaccepted){
            if(this.$route.query.paymentaccepted === 'true')
                this.successDialog = true
            else if(this.$route.query.paymentaccepted === 'false')
                this.paymentErrorDialog = true

                this.gatewayReason = this.$route.query.gatewayreason

        }

        if(this.GetSubscriptionMode === 0){
            this.headers.push(
                {
                    text: this.$t('registration-detail-vehicle-title'),
                    align: 'start',
                    sortable: true,
                    value: 'subscriptionRelations'
                },
                {
                    text: this.$t('customerportal-entrancesLeft'),
                    align: 'end',
                    sortable: true,
                    value: 'entrancesLeft'
                })
        }
        
        this.getList()
    },
    components: {
        PurchaseSubscription : () => import('@/views/components/PurchaseSubscription.vue')
    },
    computed: {
        allowPbP(){
            return process.env.VUE_APP_ALLOW_PAY_BY_PLATE === 'true'
        },
        allowSubscription(){
            return process.env.VUE_APP_ALLOW_SUBSCRIPTION === 'true'
        }, 
        ...mapGetters({
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber',
            GetSubscriptionMode: 'GetSubscriptionMode'
        })
    },
    methods: {
        getList(){
            this.FetchCustomerSubscriptions({pNumber: this.getSelectedPNumber}).then(result => {            
            this.activeSubscriptions = [...result.activeSubscriptions, result.upcomingSubscriptions]
            
            this.inactiveSubscriptions = result.inactiveSubscriptions
            
            this.FetchSubscriptionOrderItemsNotActivated({pNumber: this.getSelectedPNumber})
                .then(result => {
                
                    let subscriptions = result.subscriptions
                    this.activeSubscriptions = [...this.activeSubscriptions, ...subscriptions]
                    this.activeSubscriptions = this.activeSubscriptions.flat()
                    
                    this.activeSubscriptions = this.activeSubscriptions.sort((a, b) => {
                        return new Date(b.utcDateCreated) - new Date(a.utcDateCreated)
                    })
                    this.activeSubscriptions.map(x => {
                        x.utcSubscriptionEnd = this.$luxon(x.utcSubscriptionEnd, { input: 'formatutc', output: 'formatlocaldate' })
                        x.utcSubscriptionStart = this.$luxon(x.utcSubscriptionStart, { input: 'formatutc', output: 'formatlocaldate' })
                        x.utcDateCreated = this.$luxon(x.utcDateCreated, { input: 'formatutc', output: 'formatlocal' })
                        
                        return x
                    })

                    this.working = false
                    })
            })
        },
        closePurchaseSubscriptionDialogWithSuccess(){
            this.purchaseSubscriptionDialog = false
            this.getList()
            this.successDialog = true
        },
        ...mapActions({
            FetchCustomerSubscriptions : 'FetchCustomerSubscriptions',
            FetchSubscriptionOrderItemsNotActivated: 'FetchSubscriptionOrderItemsNotActivated'
        })
    }
}
</script>